import ServiceHelper from '../helpers/ServiceHelper';
import { Oem } from '../models/ApiModels';

export async function GetOems() {
  return new Promise<Oem[]>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: 'v1/oems',
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function GetOem(id: string) {
  return new Promise<Oem>((resolve, reject) => {
    ServiceHelper({
      method: 'GET',
      url: `v1/oems/${id}`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function AddOem(oem: Oem) {
  return new Promise<Oem>((resolve, reject) => {
    ServiceHelper({
      method: 'POST',
      url: 'v1/oems/',
      data: oem,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function UpdateOemAsync(oem: Oem) {
  return new Promise<Oem>((resolve, reject) => {
    ServiceHelper({
      method: 'PUT',
      url: `v1/oems/${oem.id}`,
      data: oem,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
