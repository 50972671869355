import { Link, useLocation } from 'react-router-dom';
import { Oem } from '../../models/ApiModels';

enum Type {
  Administration = 'Administration',
  Order = 'Order',
  Humphree = 'Humphree',
}

interface Props {
  currentTab: string;
  changeTab: (value: string) => void;
  currentOem?: Oem;
}
export function SettingsTabs(props: Props) {
  const location = useLocation();

  return (
    <div className='flex items-center w-full border-b border-gray-10 gap-8 mt-10'>
      <Link
        to='admin'
        className={`py-4 cursor-pointer w-max ${!location.pathname.includes('order') && 'border-b-2 border-gray-100'}`}
        onClick={() => props.changeTab(Type.Administration)}
      >
        <span
          className={`${
            !location.pathname.includes('order') ? 'font-bold' : 'font-normal'
          } text-primary-400 prose-paragraphBase`}
        >
          Administration
        </span>
      </Link>
      {props.currentOem !== undefined && (
        <Link
          to='order'
          className={`py-4 cursor-pointer w-max ${location.pathname.includes('order') && 'border-b-2 border-gray-100'}`}
          onClick={() => props.changeTab(Type.Order)}
        >
          <span
            className={`${
              location.pathname.includes('order') ? 'font-bold' : 'font-normal'
            } text-primary-400 prose-paragraphBase`}
          >
            Order
          </span>
        </Link>
      )}
    </div>
  );
}
