import { useMsal } from '@azure/msal-react';
import {
  faCircleQuestion,
  faCloudArrowDown,
  faCog,
  faDashboard,
  faEllipsis,
  faExternalLink,
  faFileContract,
  faGrid,
  faLock,
  faMagnifyingGlassChart,
  faShip,
  faSitemap,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useLocation } from 'react-router-dom';
import { Config } from '../../config';
import { AppContext } from '../../contexts/AppContext';
import { Logout } from '../../services/AuthService';
import PermissionGate, { ROLES } from '../../utils/PermissionGate';
import SidebarButton from './SidebarButton';
import { SidebarOemDropdown } from './SidebarOemDropdown';
interface Props {
  showMenu: boolean;
  setShowMenu: () => void;
}

function Sidebar(props: Props) {
  const { isHumphreeUser, currentOem } = useContext(AppContext);
  const location = useLocation();
  const { accounts } = useMsal();
  const account = accounts[0];
  const [openUserEdit, setOpenUserEdit] = useState(false);

  const ref = useDetectClickOutside({
    onTriggered: () => {
      setOpenUserEdit(false);
    },
  });

  function toggleMenuOnlyMobile() {
    if (window.innerWidth < 768) {
      props.setShowMenu();
    }
  }

  return (
    <>
      <div
        onClick={(e) => {
          props.setShowMenu();
        }}
        className={`absolute z-30 transition duration-500 md:hidden ${
          props.showMenu ? 'opacity-80 pointer-events-auto' : 'opacity-0 pointer-events-none'
        }
        } w-screen h-screen bg-black`}
      ></div>
      <div
        ref={ref}
        className={`transition absolute z-40 -translate-x-72 ${
          props.showMenu ? 'flex opacity-100 translate-x-0' : 'opacity-0'
        } md:static flex-col flex-shrink-0 items-start justify-between 2xl:w-80 w-60 h-full bg-gray-5 p-5 py-11`}
      >
        <div className='flex flex-col w-full'>
          <div className='max-w-40 max-h-[60px] flex self-center mb-11'>
            <img src='/humphree-logo-black.png' className=' object-contain' />
          </div>
          {isHumphreeUser ? (
            <SidebarOemDropdown />
          ) : (
            <div className='flex justify-center w-full items-center'>
              <span className='prose-heading4 text-primary-400'>{currentOem?.name}</span>
            </div>
          )}
          <div className='line w-full mt-10 bg-gray-20'></div>
          <div className='w-full flex flex-col gap-3 py-7'>
            {currentOem === undefined && (
              <SidebarButton
                active={location.pathname === '/dashboard'}
                icon={<FontAwesomeIcon icon={faDashboard} />}
                title='Dashboard'
                routeName='/dashboard'
                setShowMenu={() => toggleMenuOnlyMobile()}
              ></SidebarButton>
            )}
            <SidebarButton
              active={
                location.pathname === '/' ||
                location.pathname === '/systems/create' ||
                location.pathname.includes('/systems')
              }
              icon={<FontAwesomeIcon icon={faSitemap} title='OEM systems' />}
              title='Systems'
              routeName='/'
              setShowMenu={() => toggleMenuOnlyMobile()}
            ></SidebarButton>
            <SidebarButton
              active={location.pathname.includes('/models') || location.pathname.includes('/createModel')}
              icon={<FontAwesomeIcon icon={faShip} title='Boat models' />}
              title='Boat models'
              routeName='models'
              setShowMenu={() => toggleMenuOnlyMobile()}
            ></SidebarButton>
            {currentOem === undefined && (
              <>
                <SidebarButton
                  active={location.pathname.includes('/units')}
                  icon={<FontAwesomeIcon icon={faGrid} />}
                  title='Hardware Units'
                  routeName='units'
                  setShowMenu={() => toggleMenuOnlyMobile()}
                ></SidebarButton>
                <SidebarButton
                  active={location.pathname.includes('/measurements')}
                  icon={<FontAwesomeIcon icon={faMagnifyingGlassChart} />}
                  title='Measurements'
                  routeName='measurements'
                  setShowMenu={() => toggleMenuOnlyMobile()}
                ></SidebarButton>
                <PermissionGate roles={[ROLES.softwareManager]} rejected={undefined}>
                  <SidebarButton
                    active={location.pathname.includes('/software')}
                    icon={<FontAwesomeIcon icon={faCloudArrowDown} />}
                    title='Software update'
                    routeName='software'
                    setShowMenu={() => toggleMenuOnlyMobile()}
                  ></SidebarButton>
                </PermissionGate>
              </>
            )}
          </div>
        </div>
        <div className='flex flex-col w-full gap-3'>
          <SidebarButton
            active={location.pathname.includes('/settings')}
            icon={<FontAwesomeIcon icon={faCog} />}
            title={currentOem === undefined ? 'Humphree settings' : 'Settings'}
            routeName='settings'
            setShowMenu={() => toggleMenuOnlyMobile()}
          ></SidebarButton>
          <a
            href='https://humphree.atlassian.net/wiki/external/NjdlOTc3ZGVkMTYyNGI1Mzk1ZmNhNGFhNzBiYWEwM2Y'
            target='_blank'
            rel='noreferrer'
            className='text-gray-60 2xl:prose-mainMenuL1Default prose-mainMenuL2Default h-8 px-3 py-5 group flex flex-row w-full gap-4 justify-between items-center cursor-pointer hover:bg-white'
          >
            <div className='flex flex-row gap-3 items-center whitespace-nowrap cursor-pointer'>
              <FontAwesomeIcon
                className='text-gray-60 cursor-pointer 2xl:prose-mainMenuL1ItemActive prose-mainMenuL2ItemActive'
                icon={faCircleQuestion}
              />
              Help
              <FontAwesomeIcon
                className='text-gray-60 cursor-pointer 2xl:prose-mainMenuL1ItemActive prose-mainMenuL2ItemActive'
                icon={faExternalLink}
              />
            </div>
          </a>
          <div className='flex flex-row items-center justify-between w-full'>
            <div className='flex flex-row gap-4 items-center'>
              <div className='flex flex-col'>
                <span className='prose-mainMenuL2Default text-gray-60'>{account?.name}</span>
              </div>
            </div>
            <div
              ref={ref}
              className='relative cursor-pointer hover:bg-gray-10 px-1 rounded-full'
              onClick={() => setOpenUserEdit(!openUserEdit)}
            >
              <FontAwesomeIcon className='text-gray-60' icon={faEllipsis} />
              {openUserEdit && (
                <>
                  <div className='p-2 absolute -top-28 -right-2 flex-col bg-white shadow-card border border-gray-10 flex items-center'>
                    <div
                      onClick={() => Logout()}
                      className='flex flex-row gap-3 items-center p-2 pr-8 whitespace-nowrap hover:bg-gray-5 w-full cursor-pointer'
                    >
                      <FontAwesomeIcon className='text-gray-40 cursor-pointer text-lg' icon={faLock} />
                      <span className=' prose-paragraphBase text-gray-100 flex-nowrap flex'>Log out</span>
                    </div>
                    <a
                      href='https://humphree.com/tandc-portal/'
                      target='_blank'
                      rel='noreferrer'
                      className='prose-paragraphBase text-gray-100 flex-nowrap flex'
                    >
                      <div className='flex flex-row gap-3 items-center p-2 pr-8 whitespace-nowrap hover:bg-gray-5 w-full cursor-pointer'>
                        <FontAwesomeIcon className='text-gray-40 cursor-pointer text-lg' icon={faFileContract} />
                        About
                        <FontAwesomeIcon className='text-gray-40 cursor-pointer text-lg' icon={faExternalLink} />
                      </div>
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* Don't show in prod*/}
          {Config.b2c_domain !== 'humphreecustomers' && (
            <span className='prose-paragraphTiny mt-2 text-gray-60'>{Config.portal_version}</span>
          )}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
