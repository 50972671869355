import { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { SettingsTabs } from './SettingsTabs';

enum Type {
  Administration = 'Administration',
  Order = 'Order',
  Humphree = 'Humphree',
}

export function Settings() {
  const [currentTab, setCurrentTab] = useState('Administration');
  const { currentOem } = useContext(AppContext);

  return (
    <div className='max-w-[2000px]'>
      <div className='prose-heading3 text-primary-400 mb-20'>
        {currentOem === undefined ? 'Humphree settings' : 'Settings'}
      </div>
      <SettingsTabs
        currentTab={currentTab}
        changeTab={(value: string) => setCurrentTab(value)}
        currentOem={currentOem}
      />
      <Outlet />
    </div>
  );
}
