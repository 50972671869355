import { faPlus, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { Checkbox, Input, PrimaryButton } from 'component-library';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { GetSystemListData, systemListColumns } from '../../helpers/SystemListSetup';
import { System } from '../../models/ApiModels';
import { GetSystemsAsync } from '../../services/SystemService';
import { ActionType } from '../../store/actionTypes';
import { RootState } from '../../store/reducers/combine';
import useRequest from '../../utils/net/useRequest';
import PermissionGate, { ROLES } from '../../utils/PermissionGate';
import useWindowSize from '../../utils/UseWindowSize';
import Table from '../Table/Table';

function Systems() {
  const [searchParams, setSearchParams] = useSearchParams({ searchValue: '' });
  const size = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentOemTenantId, isHumphreeUser } = useContext(AppContext);
  const oems = useSelector((state: any) => state.oems.data);
  const features = useSelector((state: any) => state.configurations.features);
  const packages = useSelector((state: any) => state.configurations.packages);
  const boatModels = useSelector((state: RootState) => state.boatModels.boatModels);
  const [systemFetchCounter, setSystemFetchCounter] = useState(0);
  const [fetchCounter, setFetchCounter] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [toggleInternalSystems, setToggleInternalSystems] = useState(true);
  const [systems = [], isSystemIsLoading, systemsGetError] = useRequest(
    () => GetSystemsAsync(),
    [currentOemTenantId, systemFetchCounter]
  );

  useEffect(() => {
    const searchValueFromParams = searchParams.get('searchValue');
    setSearchValue(searchValueFromParams || '');
  }, [searchParams]);

  useEffect(() => {
    dispatch({
      type: ActionType.GET_BOATMODELS,
      payload: boatModels,
    });
  }, [boatModels]);

  function goToSystemDetails(value: any, event: any) {
    if (event.ctrlKey || event.metaKey || event.which === 2) {
      window.open('systems/' + systems.find((system: System) => system.id === value.humphreeId).id, '_blank');
    } else navigate(value.humphreeId);
  }

  function goToCreate() {
    setTimeout(() => {
      navigate('/create');
    }, 1);
  }

  return (
    <div className='max-w-[2000px]'>
      <span className='prose-heading3'>Systems</span>
      {isSystemIsLoading ? (
        <div className='flex items-center flex-col gap-4 h-96 justify-center'>
          <span className='text-primary-400 prose-heading5'>Loading systems...</span>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      ) : (
        <>
          <div className='mt-14 border border-gray-10 p-4 flex justify-between items-center'>
            <div className='w-1/3 mb-1'>
              <Input
                placeholder='Search for systems...'
                icon={faSearch}
                iconbefore
                value={searchValue}
                onChange={(value: string) => {
                  searchParams.set('searchValue', value);
                  setSearchValue(value);
                  searchParams.set('page', '1');
                  setSearchParams(searchParams);
                }}
              />
            </div>
            <PermissionGate roles={[ROLES.admin]} rejected={undefined}>
              <PrimaryButton
                onClick={() => goToCreate()}
                icon={faPlus}
                label='Create new system'
                size={window.innerWidth > 1500 ? 'small' : 'tiny'}
              />
            </PermissionGate>
          </div>
          {!systemsGetError ? (
            <>
              <Table
                data={GetSystemListData(systems, oems, features, packages, boatModels, toggleInternalSystems)}
                columns={systemListColumns}
                onClick={(value: any, event?: any) => goToSystemDetails(value, event)}
                searchValue={searchValue}
                type='systems'
                setColumnVisibility={{
                  humphreeId: false,
                  oemName: isHumphreeUser,
                  software: size?.width > 1400,
                  boatModel: size?.width > 1200,
                  hullNumber: size?.width > 1000,
                }}
              />
              {isHumphreeUser && (
                <div className='flex justify-end items-center p-4 gap-2 border-x border-b border-gray-10'>
                  <Checkbox
                    active={toggleInternalSystems}
                    onClick={() => setToggleInternalSystems(!toggleInternalSystems)}
                  />
                  <p className='prose-labelPill'>Hide Humphree Internal systems</p>
                </div>
              )}
            </>
          ) : (
            <div className='flex justify-between items-center p-4 px-8 bg-error-100 border-x border-gray-10'>
              <span className='text-white prose-paragraphBase italic'>Could not load systems!</span>

              <span
                onClick={() => setSystemFetchCounter(systemFetchCounter + 1)}
                className='text-white prose-paragraphBase italic cursor-pointer underline'
              >
                Try again
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default Systems;
