import { Dropdown, StatusPill } from 'component-library';
import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { BoatModel } from '../../../models/ApiModels';
import BoatModelMakeInput from './BoatModelMakeInput';
import BoatModelNameInput from './BoatModelNameInput';

type Props = {
  boatModel: BoatModel;
  editMode: boolean;
  setUpdateBoatModel: (boatModel: BoatModel) => void;
  updateBoatModel: BoatModel;
  currentOem: string;
};

function BoatModelDetailsInformation(props: Props) {
  const { isHumphreeUser } = useContext(AppContext);

  return (
    <div>
      <span className='prose-heading4'>Information</span>
      <div className='border-b border-gray-10 mt-4'></div>
      {isHumphreeUser && (
        <div className='border-b border-gray-10 flex justify-between items-center py-5'>
          <span>Owner</span>
          <span>{props.currentOem}</span>
        </div>
      )}
      {props.editMode && props.updateBoatModel && (
        <>
          <div className='border-b border-gray-10 md:flex justify-between items-center py-5'>
            <span className='flex gap-1'>Brand / make</span>
            <BoatModelMakeInput
              boatModel={props.updateBoatModel}
              setBoatModel={() => props.setUpdateBoatModel(props.updateBoatModel)}
            />
          </div>
          <div className='border-b border-gray-10 flex justify-between items-center py-5'>
            <span className='flex gap-1'>Boat model name</span>
            <BoatModelNameInput
              boatModel={props.updateBoatModel}
              setBoatModel={() => props.setUpdateBoatModel(props.updateBoatModel)}
            />
          </div>
        </>
      )}
      <div className='border-b border-gray-10 flex justify-between items-center py-5'>
        <span className='flex gap-1'>Status</span>
        {props.editMode && props.updateBoatModel ? (
          <span className='flex gap-2'>
            <Dropdown
              label={''}
              optionsData={[
                { id: 'Public', value: 'Public' },
                { id: 'Private', value: 'Private' },
              ]}
              placeholder=''
              size='Medium'
              onValueChosen={(id: string) => {
                props.updateBoatModel.status = id;
                props.setUpdateBoatModel(props.updateBoatModel);
              }}
              value={props.boatModel.status}
            />
          </span>
        ) : (
          <>
            {props.boatModel.status === 'Public' ? (
              <StatusPill color='Green' text='Public' />
            ) : (
              <StatusPill color='Dark Gray' text='Private' />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default BoatModelDetailsInformation;
