import { faCheck, faHyphen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Oem } from '../../../models/ApiModels';
interface Props {
  currentOem?: Oem;
}
function RolesOverview(props: Props) {
  const rolesAvailable = [
    {
      humphreeRole: false,
      label: 'Purchase license upgrades',
      adminAllowed: true,
      editorAllowed: false,
      viewerAllowed: false,
    },
    {
      humphreeRole: true,
      label: 'Manually override licenses',
      adminAllowed: true,
      editorAllowed: false,
      viewerAllowed: false,
    },
    {
      humphreeRole: false,
      label: 'Edit system information',
      adminAllowed: true,
      editorAllowed: true,
      viewerAllowed: false,
    },
    {
      humphreeRole: false,
      label: 'Add comments to systems',
      adminAllowed: true,
      editorAllowed: true,
      viewerAllowed: true,
    },
    {
      humphreeRole: false,
      label: 'Add and edit boat models',
      adminAllowed: true,
      editorAllowed: true,
      viewerAllowed: false,
    },
    {
      humphreeRole: true,
      label: 'Invite customer users',
      adminAllowed: true,
      editorAllowed: true,
      viewerAllowed: false,
    },
    {
      humphreeRole: true,
      label: 'Invite Humphree users',
      adminAllowed: true,
      editorAllowed: false,
      viewerAllowed: false,
    },
    {
      humphreeRole: true,
      label: 'Hardware units section',
      adminAllowed: true,
      editorAllowed: true,
      viewerAllowed: true,
    },
  ];

  function Roles() {
    const roles = props.currentOem !== undefined ? rolesAvailable.filter((item) => !item.humphreeRole) : rolesAvailable;
    return roles.map((item) => {
      return (
        <div className='grid grid-cols-6' key={item.label}>
          <div className='col-span-3 text-left prose-paragraphBase'>{item.label}</div>

          <FontAwesomeIcon
            className={`flex self-center justify-self-center ${item.adminAllowed && 'text-primary-100'}`}
            icon={item.adminAllowed ? faCheck : faHyphen}
          />

          <FontAwesomeIcon
            className={`flex self-center justify-self-center ${item.editorAllowed && 'text-primary-100'}`}
            icon={item.editorAllowed ? faCheck : faHyphen}
          />

          <FontAwesomeIcon
            className={`flex self-center justify-self-center ${item.viewerAllowed && 'text-primary-100'}`}
            icon={item.viewerAllowed ? faCheck : faHyphen}
          />
        </div>
      );
    });
  }

  return (
    <div className='flex flex-col w-full'>
      <span className='prose-heading4 text-gray-80 mb-4'>
        {props.currentOem === undefined ? 'Humphree roles' : 'Roles'} overview
      </span>

      <div className='grid grid-cols-6 text-center mb-2'>
        <div className='col-start-4 prose-labelStandard'>ADMIN</div>
        <div className='prose-labelStandard'>EDITOR</div>
        <div className='prose-labelStandard'>VIEW ONLY</div>
      </div>
      <div className='grid gap-1'>{Roles()}</div>
    </div>
  );
}

export default RolesOverview;
