import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { Input, InvertButton, PrimaryButton, Tooltip } from 'component-library';
import _ from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppContext } from '../../contexts/AppContext';
import { Oem } from '../../models/ApiModels';
import { GetOem, UpdateOemAsync } from '../../services/OemService';
import { ActionType } from '../../store/actionTypes';
import PermissionsGate, { ROLES } from '../../utils/PermissionGate';

export function SettingsOrder() {
  const [editMode, setEditMode] = useState(false);
  const { currentOem } = useContext(AppContext);
  const dispatch = useDispatch();
  const { setCurrentOem, currentOemTenantId } = useContext(AppContext);
  const [updateOem, setUpdateOem] = useState(_.cloneDeep(currentOem));

  const hasChanged = useMemo(() => {
    return !_.isEqual(currentOem, updateOem);
  }, [updateOem]);

  function UpdateOem() {
    if (updateOem) {
      UpdateOemAsync(updateOem).then(() => {
        dispatch({
          type: ActionType.SET_SNACKBAR,
          payload: { heading: 'Successfully updated OEM!', status: 'success' },
        });
        GetOem(currentOemTenantId).then((res: Oem) => setCurrentOem(res));
        setEditMode(!editMode);
      });
    }
  }
  return (
    <div className='flex flex-col pt-8'>
      <PermissionsGate roles={[ROLES.admin, ROLES.editor]} allowOemUsers rejected={undefined}>
        <div className='flex gap-2 ml-auto w-max sticky -mt-20 mb-20 top-0 right-0 z-10'>
          {!editMode ? (
            <PrimaryButton label={'Edit order settings'} icon={faEdit} onClick={() => setEditMode(!editMode)} />
          ) : (
            <>
              <InvertButton
                label={'Leave edit'}
                onClick={() => {
                  setEditMode(!editMode);
                }}
              />
              <PrimaryButton
                label={'Save settings'}
                disabled={!hasChanged}
                onClick={() => {
                  UpdateOem();
                }}
              />
            </>
          )}
        </div>
      </PermissionsGate>
      <div className='flex flex-col border-b border-gray-10 pb-5'>
        <span className='prose-heading4 pb-2'>Order settings</span>
        <span className='prose-paragraphSmall text-gray-60'>Edit settings related to ordering from the portal.</span>
        <div className='flex flex-row justify-between w-full mt-8'>
          <div className='flex flex-row gap-2 items-center justify-start'>
            <span className='prose-paragraphBase text-gray-100'>Default order confirmation email</span>
            <Tooltip description='Default order confirmation email' />
          </div>
          {!editMode ? (
            <span className='prose-paragraphBase text-gray-100'>{currentOem?.settings.orderConfirmationEmail}</span>
          ) : (
            <div className='w-60'>
              <Input
                placeholder='Enter email...'
                value={updateOem?.settings?.orderConfirmationEmail || ''}
                onChange={(value: string) => {
                  if (updateOem && updateOem.settings) {
                    updateOem.settings.orderConfirmationEmail = value;
                    setUpdateOem({ ...updateOem });
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
