import { StatusPill, Tooltip } from 'component-library';
import { useMemo } from 'react';
import { Oem, SystemDetail } from '../../../models/ApiModels';

interface Props {
  system: SystemDetail;
  currentOem?: Oem;
}

function SystemDetailsInfo(props: Props) {
  const formatDate = (date: Date | string) => date.toString().slice(0, 10);
  const today = new Date();

  const isToday = (date: Date) => {
    return (
      date.getDate() == today.getDate() &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear()
    );
  };

  const lastConnected = useMemo(() => {
    if (props.system.latestConnectionToCloudDate) {
      const latestConnectionDate = new Date(props.system.latestConnectionToCloudDate);
      if (isToday(latestConnectionDate))
        return (
          'Today at ' +
          latestConnectionDate.getHours() +
          ':' +
          (latestConnectionDate.getMinutes() < 10 ? '0' : '') +
          latestConnectionDate.getMinutes()
        );
      else {
        const difference = today.getTime() - latestConnectionDate.getTime();
        const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return totalDays.toString() + (totalDays == 1 ? ' day ago' : ' days ago');
      }
    }
  }, [props.system.latestConnectionToCloudDate]);

  const lastConnectedStatusColor = useMemo(() => {
    if (props.system.latestConnectionToCloudDate) {
      const latestConnectionDate = new Date(props.system.latestConnectionToCloudDate);
      if (isToday(latestConnectionDate)) {
        return 'Light Blue';
      } else {
        const difference = today.getTime() - latestConnectionDate.getTime();
        const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
        if (totalDays > 249 && totalDays < 500) return 'Warning';
        else if (totalDays > 499) return 'Light Red';
        else return 'Green';
      }
    }

    return 'Green';
  }, [props.system.latestConnectionToCloudDate]);

  function addYears(date: Date, years: number) {
    const dateCopy = new Date(date);
    dateCopy.setFullYear(dateCopy.getFullYear() + years);
    return dateCopy;
  }

  const GetWarrantyStatusColor = useMemo(() => {
    const now = new Date();
    if (props.system.warrantyStartDate) {
      const newDate = addYears(props.system.warrantyStartDate, 2);
      if (now > newDate) return 'Light Red';
      else return 'Green';
    } else if (props.system.createdDate) {
      const newDate = addYears(props.system.createdDate, 1);
      if (now > newDate) return 'Light Red';
      else return 'Green';
    } else return 'Light Red';
  }, []);

  return (
    <div className='flex flex-col'>
      <h1 className='prose-heading4 border-b border-gray-10 pb-5'>System information</h1>
      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center'>
        <h2 className='prose-paragraphBase mb-1 flex w-full flex-col'>
          <span className='flex gap-2'>
            Humphree-ID
            <Tooltip
              description='The unique ID of a Humphree system which is used as reference in the system lifecycle.'
              darkmode
            />
          </span>
        </h2>
        <p className='flex items-center prose-paragraphBase'>{props.system?.id}</p>
      </div>
      <div className='flex flex-row justify-between items-center border-b border-gray-10 py-5'>
        <h2 className='prose-paragraphBase mb-1'>Creation date</h2>
        <p className='flex items-center'>{formatDate(props.system?.createdDate ? props.system.createdDate : '')}</p>
      </div>
      {lastConnected && (
        <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center w-full whitespace-nowrap'>
          <div className='prose-paragraphBase mb-1 flex flex-row w-full whitespace-pre-wrap gap-2'>
            <span>Last connected</span>
            <Tooltip
              description='Last connected shows how many days ago the system communicated with the Humphree. To reconnect, connect the system to the internet.'
              darkmode
            />
          </div>
          <StatusPill color={lastConnectedStatusColor} text={lastConnected.toString()} />
        </div>
      )}
      {props.system.warrantyStartDate && (
        <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center w-full whitespace-nowrap'>
          <div className='prose-paragraphBase mb-1 flex flex-row w-full whitespace-pre-wrap gap-2'>
            <span>Warranty start</span>
            <Tooltip
              description='Warranty start is either the day the system was changed to installing, or the warranty start date if the system received a warranty form.'
              darkmode
            />
          </div>
          <span>{formatDate(props.system.warrantyStartDate)}</span>
        </div>
      )}
      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center w-full whitespace-nowrap'>
        <div className='prose-paragraphBase mb-1 flex flex-row w-full whitespace-pre-wrap gap-2'>
          <span>Warranty status</span>
          <Tooltip
            description='Warranty status is active until 12 months from the system created date, or if a warranty form has been submitted, 24 months from the boat delivery date in the form.'
            darkmode
          />
        </div>
        <StatusPill color={GetWarrantyStatusColor} text={GetWarrantyStatusColor === 'Green' ? 'Active' : 'Expired'} />
      </div>
      { props.system?.salesShipmentNumber && 
      <div className='flex flex-row justify-between border-b border-gray-10 py-5 items-center'>
        <h2 className='prose-paragraphBase mb-1 flex w-full flex-col'>
          <span className='flex gap-2'>
            Original document number
            <Tooltip
              description='The document number from the sales shipment order.'
              darkmode
            />
          </span>
        </h2>
        <p className='flex items-center prose-paragraphBase'>{props.system?.salesShipmentNumber}</p>
      </div>
      }
    </div>
  );
}

export default SystemDetailsInfo;
